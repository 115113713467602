import { defineStore } from 'pinia';
import { parseCollectionData, parseItemInCollection } from '~/utils/strapiComponents/addStrapiData';
import { useStoreRoot } from '../abstract/root';
import type { InfoPageData } from '~/types/infoPages';
import type { EPageType } from '~/types/pageType';

export const useStoreInfoPages = defineStore('infoPages', {
  state: () => ({
    infoPages: [] as InfoPageData[],
    pageType: null as EPageType | null,
    pageData: {} as Record<string, any>,
  }),

  getters: {
    data(state) {
      const storeRoot = useStoreRoot();

      return state.infoPages.filter((item) => item.locale === storeRoot.userLang);
    },
  },

  actions: {
    ADD_INFO_PAGES_FROM_STRAPI(data: InfoPageData[]) {
      const parsedData = parseCollectionData(data);
      const filteredState = this.infoPages.filter(
        (item) => !data.some(({ id }) => id && id === item.id)
      );

      this.infoPages = [...filteredState, ...parsedData];
    },
    ADD_OR_EDIT_INFOPAGE_FROM_STRAPI(pageData: InfoPageData) {
      let page: InfoPageData = JSON.parse(JSON.stringify(pageData));
      const index = this.infoPages.findIndex((p) => p.id === page.id);
      page = parseItemInCollection(page);

      if (index > -1) {
        this.infoPages.splice(index, 1, page);
      } else {
        this.infoPages.push(page);
      }
    },
    SET_PAGE_TYPE(data: EPageType) {
      this.pageType = data;
    },
    SET_PAGE_DATA(data: Record<string, any>) {
      this.pageData = data;
    },
  },
});
