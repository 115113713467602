import { useStoreRoot } from '~/stores/abstract/root';
import { useStoreCountry } from '~/stores/client/country';
import { useStoreStag } from '~/stores/client/stag';
import { useStoreSsAccounts } from '~/stores/softswiss/accounts';
import { useStoreSsPlayer } from '~/stores/softswiss/player';

interface UserDataInterface {
  vUserType?: 'player' | 'guest';
  vUserId?: string | number;
  vStag?: string | number;
  vUserCurrency?: string;
  vUserCountry?: string;
  vBonusCode?: string;
  vGroups?: string[];
  vVipLevel?: string | number;
  vUserBalance?: string | number;
  vUserAvailibleCashout?: string | number;
}

export default defineNuxtPlugin(() => {
  const router = useRouter();

  if (!router) {
    return;
  }

  router.afterEach(async (to, _from) => {
    if (!import.meta.browser) {
      return;
    }

    const userData: UserDataInterface = { vUserType: 'guest' };

    const storeSsPlayer = useStoreSsPlayer();
    const storeCountry = useStoreCountry();
    const storeSsAccounts = useStoreSsAccounts();
    const storeStag = useStoreStag();
    const storeRoot = useStoreRoot();

    if (!storeCountry.country) {
      await storeCountry.check();
    }

    if (storeSsPlayer.isPlayerAuthorised) {
      await storeSsAccounts.loadPlayerBalance();

      userData.vUserId = storeSsPlayer.playerId ?? undefined;
      userData.vUserType = 'player';
      userData.vBonusCode = storeSsPlayer.playerInfo.deposit_bonus_code ?? undefined;
      userData.vGroups = storeSsPlayer.playerGroupIds;
      userData.vVipLevel = storeSsPlayer.playerLevel;

      const balance = storeSsAccounts.activeBalance;

      userData.vUserBalance = balance?.amount_cents || 0;
      userData.vUserAvailibleCashout = balance?.available_to_cashout_cents || 0;
    }

    userData.vStag = storeStag.currentPureStag;
    userData.vUserCurrency = storeRoot.userCurrency;
    userData.vUserCountry = storeCountry.country;

    useGtm()?.push({
      event: 'nuxtRoute',
      routeName: to.name, // "pageId___en___default"
      pageType: 'PageView',
      pageUrl: to.fullPath, // "/hits"
      pageTitle: (typeof document !== 'undefined' && document.title) || '', // "Most Popular Casino Games Online | 7BitCasino"
      ...userData,
    });
  });
});
