<template>
  <NuxtLoadingIndicator color="#6767E4" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useStoreSsPaymentMethods } from '~/stores/softswiss/paymentMethods';
import { useStoreQuery } from './stores/client/query';
import '@bit/ui-kit/styles';
import '@/assets/styles/loader.css';
import '@/assets/styles/default.css';
import '@/assets/styles/main.scss';
import '@/assets/ui-kit/reset.scss';
import '@/assets/ui-kit/modals.scss';
import '@/assets/ui-kit/typography.scss';

const platform = usePlatform();
const route = useRoute();

if (import.meta.browser) {
  useStoreSsPaymentMethods().loadPaymentLib();

  watch(
    () => import.meta.browser && route.fullPath,
    () => {
      useStoreQuery().updateRoute(route);
    },
    { immediate: true }
  );
}

// Setup event listeners for resizing
onMounted(() => {
  platform.updateScreenSize();
  window.addEventListener('resize', platform.updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', platform.updateScreenSize);
});
</script>
