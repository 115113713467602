import { useStoreRoot } from '~/stores/abstract/root';
import type { TLanguage } from '~/types/language';

export default defineNuxtPlugin({
  hooks: {
    'app:created': () => {
      const ctx = useNuxtApp();

      const mirrorGeo = useStoreRoot().geo || 'main';

      let defaultLocale = 'en';

      if (mirrorGeo === 'it') {
        defaultLocale = 'it';
      }

      if (mirrorGeo === 'de-ch') {
        defaultLocale = 'de';
      }

      if (mirrorGeo === 'et') {
        defaultLocale = 'et';
      }

      if (mirrorGeo === 'fi') {
        defaultLocale = 'fi';
      }

      useStoreRoot().setDefaultLocale(defaultLocale);

      if (defaultLocale !== 'en' && !/^\/([a-z]{2})(\/|$)/.test(useRequestURL().pathname)) {
        ctx.$i18n.defaultLocale = defaultLocale;
        useNuxtApp().$i18n.defaultLocale = defaultLocale;
        useRuntimeConfig().public.i18n.defaultLocale = defaultLocale;
        useNuxtApp().$i18n.locale.value = defaultLocale;
      }

      useStoreRoot().setLang(useNuxtApp().$i18n.locale.value as TLanguage);
    },
  },
});
