import { default as _91_91pageId_93_93PDFy34z3iUMeta } from "/app01/pages/[[pageId]].vue?macro=true";
import { default as depositf99xiI09y4Meta } from "/app01/pages/deposit.vue?macro=true";
import { default as _91gameName_93249Fxz4hESMeta } from "/app01/pages/games/[gameName].vue?macro=true";
import { default as _91gameName_93dqze0IvT3sMeta } from "/app01/pages/games/[provider]/[gameName].vue?macro=true";
import { default as not_45foundeEbydRdInEMeta } from "/app01/pages/not-found.vue?macro=true";
import { default as not_45foundSkeletonqPnEwDEuVLMeta } from "/app01/pages/not-foundSkeleton.vue?macro=true";
import { default as registerC2j25gNspXMeta } from "/app01/pages/register.vue?macro=true";
import { default as cache_45clearZFitXMS6EYMeta } from "/app01/pages/service/cache-clear.vue?macro=true";
import { default as _91pageId_93L25VwSUFPEMeta } from "/app01/pages/slot-themes/[pageId].vue?macro=true";
import { default as component_45stubVzULtA3w48Meta } from "/app01/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVzULtA3w48 } from "/app01/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "pageId___en___default",
    path: "/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___en",
    path: "/en/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___de",
    path: "/de/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___fr",
    path: "/fr/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___it",
    path: "/it/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___et",
    path: "/et/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "pageId___fi",
    path: "/fi/:pageId?",
    component: () => import("/app01/pages/[[pageId]].vue")
  },
  {
    name: "deposit___en___default",
    path: "/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___en",
    path: "/en/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___de",
    path: "/de/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___fr",
    path: "/fr/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___it",
    path: "/it/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___et",
    path: "/et/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "deposit___fi",
    path: "/fi/deposit",
    meta: depositf99xiI09y4Meta || {},
    component: () => import("/app01/pages/deposit.vue")
  },
  {
    name: "games-gameName___en___default",
    path: "/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___en",
    path: "/en/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___de",
    path: "/de/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___fr",
    path: "/fr/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___it",
    path: "/it/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___et",
    path: "/et/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-gameName___fi",
    path: "/fi/games/:gameName()",
    meta: _91gameName_93249Fxz4hESMeta || {},
    component: () => import("/app01/pages/games/[gameName].vue")
  },
  {
    name: "games-provider-gameName___en___default",
    path: "/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___en",
    path: "/en/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___de",
    path: "/de/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___fr",
    path: "/fr/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___it",
    path: "/it/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___et",
    path: "/et/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "games-provider-gameName___fi",
    path: "/fi/games/:provider()/:gameName()",
    meta: _91gameName_93dqze0IvT3sMeta || {},
    component: () => import("/app01/pages/games/[provider]/[gameName].vue")
  },
  {
    name: "not-found___en___default",
    path: "/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___en",
    path: "/en/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___de",
    path: "/de/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___fr",
    path: "/fr/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___it",
    path: "/it/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___et",
    path: "/et/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-found___fi",
    path: "/fi/not-found",
    component: () => import("/app01/pages/not-found.vue")
  },
  {
    name: "not-foundSkeleton___en___default",
    path: "/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___en",
    path: "/en/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___de",
    path: "/de/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___fr",
    path: "/fr/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___it",
    path: "/it/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___et",
    path: "/et/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "not-foundSkeleton___fi",
    path: "/fi/not-foundSkeleton",
    component: () => import("/app01/pages/not-foundSkeleton.vue")
  },
  {
    name: "register___en___default",
    path: "/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___de",
    path: "/de/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___et",
    path: "/et/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "register___fi",
    path: "/fi/register",
    meta: registerC2j25gNspXMeta || {},
    component: () => import("/app01/pages/register.vue")
  },
  {
    name: "service-cache-clear___en___default",
    path: "/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___en",
    path: "/en/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___de",
    path: "/de/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___fr",
    path: "/fr/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___it",
    path: "/it/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___et",
    path: "/et/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "service-cache-clear___fi",
    path: "/fi/service/cache-clear",
    meta: cache_45clearZFitXMS6EYMeta || {},
    component: () => import("/app01/pages/service/cache-clear.vue")
  },
  {
    name: "slot-themes-pageId___en___default",
    path: "/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___en",
    path: "/en/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___de",
    path: "/de/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___fr",
    path: "/fr/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___it",
    path: "/it/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___et",
    path: "/et/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: "slot-themes-pageId___fi",
    path: "/fi/slot-themes/:pageId()",
    component: () => import("/app01/pages/slot-themes/[pageId].vue")
  },
  {
    name: component_45stubVzULtA3w48Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubVzULtA3w48
  }
]